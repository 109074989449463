import { useEffect } from 'react';

import { DateTime } from 'luxon';
import { useSearchParams } from 'react-router-dom';

import {
    useGetAvailableWarehousesQuery,
    useGetShippingServicesQuery,
    useGetTrackOrderSettingsQuery
} from '@apis/trackOrders/TrackOrders.api';
import {
    GetAvailableWarehousesResponse,
    GetShippingServices
} from '@apis/trackOrders/TrackOrdersApi.types';
import {
    Pagination,
    Sorting,
    SORTING_DIRECTION
} from '@customTypes/general/general.types';
import { OrdersListSearchParams } from '@customTypes/mirAndTracking/MirAndTracking.types';
import { useParamsHelper } from '@helpers/paramsHandler';
import { getPaginationObject } from '@helpers/tablePaginationAndSorting';
import { useSelectedTransportType } from '@hooks/useAllTreeHelpers';
import { useUser } from '@hooks/useUser';
import {
    getAccountsAndSubsFromUrl,
    getDateFilterData,
    getFilters,
    getWarehousesFromUrl,
    DateFilterData,
    WAREHOUSE_FILTER_PREFIX,
    toUrlSearchParams
} from '@organisms/trackOrders/orderList/ordersListUrlUtils';
import { useCurrentParams } from '@organisms/trackOrders/orderList/useCurrentParams';

export interface OrdersListUrlReturnType {
    setParams: (newParams?: OrdersListSearchParams, replace?: boolean) => void;
    pagination: Pagination;
    sorting: Sorting;
    orderTypes?: string[];
    productConditionTypes?: string[];
    deliveryTypes?: string[];
    removeParam: <T>(params: string[], paramsToSave?: T) => void;
    dateFilterData: DateFilterData;
    minAvailableDateRange: Date;
    search?: string;
    status?: string[];
    filters: string;
    accountsAndSubs?: string[];
    transportTypes?: string[];
    originLocations?: string[];
    destinationLocations?: string[];
    showDangerousGoods?: string;
    warehousesData?: GetAvailableWarehousesResponse;
    isDestinationsLoading?: boolean;
    showWatchList?: string;
    allTransportTypes?: GetShippingServices;
    shippingServices: number[];
}

export const useOrdersListUrl = (): OrdersListUrlReturnType => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { accounts, fetchAccounts } = useUser();
    const { data: allTransportTypes } = useGetShippingServicesQuery();

    const { data: warehousesData, isLoading: isDestinationsLoading } =
        useGetAvailableWarehousesQuery();

    const { data: trackOrderSettings } = useGetTrackOrderSettingsQuery();
    const defaultDateRangeInDays = trackOrderSettings?.data[0];
    const generalDateRangeInMonths = trackOrderSettings?.data[1];

    const currentDate = DateTime.now();
    const newDate = currentDate.minus({
        months: generalDateRangeInMonths?.value
    });

    const minAvailableDateRange = newDate.toJSDate();

    const sortingInitialState: Sorting = {
        field: 'LatestMilestoneDate',
        direction: SORTING_DIRECTION.DESC
    };

    const { currentParams } = useCurrentParams(
        searchParams,
        sortingInitialState
    );

    useEffect(() => {
        fetchAccounts?.(true);
    }, []);

    const {
        sortingData,
        page,
        pageSize,
        orderTypes,
        productConditionTypes,
        deliveryTypes,
        dateFrom,
        dateTo,
        dateType,
        search,
        accountsAndSubs,
        originLocations,
        destinationLocations,
        showDangerousGoods,
        status,
        showWatchList,
        transportTypes
    } = currentParams;

    const { selectedTransportTypes } = useSelectedTransportType(
        transportTypes?.split(','),
        allTransportTypes?.data
    );

    const { setParams, removeParam } = useParamsHelper({
        setSearchParams,
        currentParams,
        toUrlSearchParams: toUrlSearchParams
    });

    const orderTypesData = orderTypes?.split(',');
    const productConditionTypesData = productConditionTypes?.split(',');
    const deliveryTypesData = deliveryTypes?.split(',');
    const accountsData = getAccountsAndSubsFromUrl(accounts, accountsAndSubs);
    const originLocationData = getWarehousesFromUrl(
        WAREHOUSE_FILTER_PREFIX.ORIGIN,
        originLocations,
        warehousesData?.data
    );
    const destinationLocationData = getWarehousesFromUrl(
        WAREHOUSE_FILTER_PREFIX.DESTINATION,
        destinationLocations,
        warehousesData?.data
    );

    const dateDataSetting = getDateFilterData({
        defaultDateRangeDays: defaultDateRangeInDays?.value || 14,
        dateFrom,
        dateTo,
        dateType
    });

    const shippingServices = selectedTransportTypes.flatMap(
        (item) => item.shippingIds || []
    );

    return {
        pagination: getPaginationObject(page, pageSize),
        sorting: sortingData || sortingInitialState,
        setParams,
        orderTypes: orderTypesData,
        productConditionTypes: productConditionTypesData,
        deliveryTypes: deliveryTypesData,
        accountsAndSubs: accountsData,
        transportTypes: transportTypes?.split(','),
        originLocations: originLocationData,
        destinationLocations: destinationLocationData,
        showDangerousGoods: showDangerousGoods,
        showWatchList: showWatchList,
        dateFilterData: dateDataSetting,
        minAvailableDateRange,
        removeParam,
        search,
        status: status?.split('|'),
        allTransportTypes,
        shippingServices,
        filters: getFilters(
            {
                search,
                accountsAndSubs: accountsData,
                transportTypes: shippingServices,
                originLocations: originLocationData,
                destinationLocations: destinationLocationData,
                orderTypes: orderTypesData,
                productConditionTypes: productConditionTypesData,
                deliveryTypes: deliveryTypesData,
                date: dateDataSetting.filterData,
                showDangerousGoods: showDangerousGoods,
                showWatchList: showWatchList,
                status: status === 'null' ? '' : status,
                accounts,
                warehouses: warehousesData?.data,
                shippingServices
            },
            !!search
        ),
        warehousesData,
        isDestinationsLoading
    };
};
