import { MutableRefObject, useEffect, useState } from 'react';

interface Props {
    scrollRef: MutableRefObject<HTMLDivElement | null>;
    isOpen: boolean;
}

export const useNotificationScroll = ({ scrollRef, isOpen }: Props) => {
    const [topNotificationId, setTopNotificationId] = useState<string | null>(
        null
    );

    const [isScrollToTopVisible, setScrollToTopVisible] = useState(false);

    const handleScrollToTop = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
        setScrollToTopVisible(false);
    };

    const handleScrollToTopVisible = (value: boolean) => {
        setScrollToTopVisible(value);
    };

    const scrollToTopNotification = () => {
        if (scrollRef && scrollRef.current) {
            const { scrollTop } = scrollRef.current;
            const notificationElements = scrollRef.current.children;

            const wasAtTop = scrollTop === 0;
            if (!wasAtTop) return;

            const nestedElements = notificationElements[0]?.children;

            if (nestedElements) {
                const topNotificationElement = nestedElements.namedItem(
                    String(topNotificationId)
                );

                if (topNotificationElement) {
                    topNotificationElement.scrollIntoView({
                        block: 'start',
                        behavior: 'auto'
                    });
                }
            }
        }
    };

    const onNewNotificationToTopChange = () => {
        if (scrollRef && scrollRef.current) {
            handleScrollToTopVisible(true);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            setScrollToTopVisible(false);
        }
    }, [isOpen]);

    return {
        scrollToTopNotification,
        setTopNotificationId,
        handleScrollToTop,
        onNewNotificationToTopChange,
        isScrollToTopVisible,
        topNotificationId
    };
};
