import { createSlice } from '@reduxjs/toolkit';

import { customerDeliveryOrderByProductApi } from '@apis/customerDeliveryOrderByProduct/CustomerDeliveryOrderByProductApi';
import {
    inboundReplenishmentApi,
    inboundOrderByProductDefectiveApi
} from '@apis/inboundReplenishment/InboundReplenishment.api';
import { inboundReturnApi } from '@apis/inboundReturn/InboundReturn.api';
import {
    materialsTransferByWarehouseApi,
    materialsTransferByWarehouseDefectiveApi
} from '@apis/materialsTransferByWarehouse/MaterialsTransferByWarehouse.api';
import {
    outboundOrderByWarehouseApi,
    outboundOrderByWarehouseDefectiveApi
} from '@apis/outboundOrderByWarehouse/OutboundOrderByWarehouse.api';
import { warehouseApi } from '@apis/warehouse/Warehouse.api';
import { SubmitOrder } from '@customTypes/api/api.types';
import {
    Delivery,
    Service
} from '@customTypes/deliveryServices/deliveryServices.types';
import { Country } from '@customTypes/general/general.types';
import { ProductFormValue } from '@customTypes/general/product.types';
import { Serial } from '@customTypes/general/referenceAndSerial.types';
import { StepperMainInfo } from '@customTypes/general/stepper.types';
import {
    AccountWarehouse,
    NullableAccountWarehouse
} from '@customTypes/general/warehouse.type';
import {
    ACTIVE_ORDER_TYPE,
    ChosenDelivery,
    OrderReferenceLabels
} from '@customTypes/order/order.types';
import { Action } from 'store/index';

export interface ActiveOrderState {
    redirectBlocker: boolean;
    activeStep: number;
    chosenProducts: ProductFormValue[];
    mainData: StepperMainInfo;
    selectedCountry: Country;
    chosenSerials: Serial[];
    orderSubmit: SubmitOrder | null;
    loading: boolean;
    firstStepFormCompleted: boolean;
    secondStepFormCompleted: boolean;
    thirdStepFormCompleted: boolean;
    forthStepFormCompleted: boolean;
    referenceNumberLabels: OrderReferenceLabels;
    chosenDelivery: ChosenDelivery;
    delivery: Delivery[];
    chosenWarehouse: NullableAccountWarehouse;
    orderType?: ACTIVE_ORDER_TYPE;
    isBrowserBackButtonBlocked?: boolean;
    carriersData: Service[];
    availableWarehouseList: AccountWarehouse[];
    unavailableWarehouseList: AccountWarehouse[];
    chosenDestinationWarehouse: NullableAccountWarehouse;
    showUnexpectedError: boolean;
    isFullWarehouseList: boolean;
}

const initialState: ActiveOrderState = {
    redirectBlocker: false,
    activeStep: 1,
    chosenProducts: [],
    chosenSerials: [],
    chosenWarehouse: null,
    chosenDestinationWarehouse: null,
    mainData: {
        totalWeight: 0,
        products: [],
        destinationWarehouse: null,
        originWarehouse: null,
        location: { countryName: '', postalCode: '' },
        deliveryType: null,
        orderReferenceNumber: '',
        orderReferenceNumber2: '',
        orderReferenceNumber3: '',
        orderReferenceNumber4: '',
        deliveryDetails: null,
        phoneCode: '',
        phoneCountry: '',
        submittedOrderData: {
            accountNum: '',
            subAccountNum: ''
        }
    },
    orderSubmit: null,
    loading: false,
    chosenDelivery: {
        warehouse: null,
        service: null
    },
    delivery: [],
    selectedCountry: {
        countryCode: '',
        countryId: 0,
        countryName: '',
        hasPostalCodes: false,
        internationalCallingCode: '',
        postalCodeRegEx: null
    },
    firstStepFormCompleted: false,
    secondStepFormCompleted: false,
    thirdStepFormCompleted: false,
    forthStepFormCompleted: false,
    referenceNumberLabels: {
        orderReferenceNumber1Label: null,
        orderReferenceNumber2Label: null,
        orderReferenceNumber3Label: null,
        orderReferenceNumber4Label: null
    },
    carriersData: [],
    isFullWarehouseList: false,
    availableWarehouseList: [],
    unavailableWarehouseList: [],
    isBrowserBackButtonBlocked: false,
    showUnexpectedError: false
};

export const activeOrderSlice = createSlice({
    name: 'activeOrder',
    initialState,
    reducers: {
        setToggleFullWarehouseList(state, action) {
            state.isFullWarehouseList = action.payload;
        },
        setActiveStep(state, action: Action<number>) {
            state.activeStep = action.payload;
        },
        editMainInfo(state, action: Action<Partial<StepperMainInfo>>) {
            state.mainData = {
                ...state.mainData,
                ...action.payload
            };
        },
        setChosenProducts(
            state,
            action: Action<{ products: ProductFormValue[] }>
        ) {
            const productsData = action.payload.products.map((item) => {
                return { ...item, quantity: item.quantity || 1 };
            });
            productsData.sort((a, b) => {
                return a.partNumber.localeCompare(b.partNumber);
            });
            state.chosenProducts = productsData;
        },
        removeProduct(state, action: Action<{ productId: number }>) {
            state.chosenProducts = state.chosenProducts.filter((item) => {
                return item.partId !== action.payload.productId;
            });
        },
        addSelectedCountry(state, action: Action<Country>) {
            state.selectedCountry = action.payload;
        },
        setFirstStepFormState(state, action: Action<boolean>) {
            state.firstStepFormCompleted = action.payload;
        },
        setSecondStepFormState(state, action: Action<boolean>) {
            state.secondStepFormCompleted = action.payload;
        },
        setThirdStepFormState(state, action: Action<boolean>) {
            state.thirdStepFormCompleted = action.payload;
        },
        setForthStepFormState(state, action: Action<boolean>) {
            state.forthStepFormCompleted = action.payload;
        },
        setOrderType(state, action: Action<ACTIVE_ORDER_TYPE>) {
            state.orderType = action.payload;
        },
        editProduct(
            state,
            action: Action<{
                partId: number;
                newData: Omit<Partial<ProductFormValue>, 'partId'>;
            }>
        ) {
            state.chosenProducts = state.chosenProducts.map((item) => {
                if (item.partId === action.payload.partId) {
                    return { ...item, ...action.payload.newData };
                }
                return item;
            });
        },
        setChosenSerials(state, action: Action<{ serials: Serial[] }>) {
            state.chosenSerials = action.payload.serials;
        },
        removeSerial(state, action: Action<{ id: string }>) {
            state.chosenSerials = state.chosenSerials.filter(
                (item) => item.id !== action.payload.id
            );
        },
        setAvailableWarehouseList(state, action: Action<AccountWarehouse[]>) {
            state.availableWarehouseList = action.payload;
        },
        setUnavailableWarehouseList(state, action: Action<AccountWarehouse[]>) {
            state.unavailableWarehouseList = action.payload;
        },
        setDelivery(state, action: Action<Delivery>) {
            state.delivery = [...state.delivery, action.payload];
        },
        setChosenDelivery(state, action: Action<Partial<ChosenDelivery>>) {
            state.chosenDelivery = {
                ...state.chosenDelivery,
                ...action.payload
            };
        },
        setOrderSubmit(state, action: Action<SubmitOrder>) {
            state.orderSubmit = action.payload;
        },
        setChosenWarehouse(state, action: Action<NullableAccountWarehouse>) {
            state.chosenWarehouse = action.payload;
        },
        setRedirectBlocker(state, action: Action<boolean>) {
            state.redirectBlocker = action.payload;
        },
        resetChosenDelivery(state) {
            state.chosenDelivery = initialState.chosenDelivery;
        },
        resetDelivery(state) {
            state.delivery = initialState.delivery;
        },
        resetChosenProducts(state) {
            state.chosenProducts = initialState.chosenProducts;
        },
        resetSelectedCountry(state) {
            state.selectedCountry = initialState.selectedCountry;
        },
        resetOrderState(state) {
            return {
                ...initialState,
                referenceNumberLabels: state.referenceNumberLabels,
                orderType: state.orderType
            };
        },
        setShowUnexpectedError(state, action: Action<boolean>) {
            state.showUnexpectedError = action.payload;
            state.redirectBlocker = false;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            inboundReturnApi.endpoints.getAccountWarehouse.matchPending,
            (state) => {
                state.loading = true;
            }
        );
        builder.addMatcher(
            inboundReturnApi.endpoints.getAccountWarehouse.matchFulfilled,
            (state, { payload }) => {
                state.availableWarehouseList = payload.data.available;
                state.unavailableWarehouseList = payload.data.unavailable;
                state.chosenDelivery = {
                    ...state.chosenDelivery,
                    warehouse: payload.data.available
                        ? payload.data.available[0]
                        : null
                };
                state.loading = false;
            }
        );
        builder.addMatcher(
            inboundReturnApi.endpoints.getAccountWarehouse.matchRejected,
            (state) => {
                state.loading = false;
            }
        );
        builder.addMatcher(
            warehouseApi.endpoints.getAvailableWarehouse.matchPending,
            (state) => {
                state.loading = true;
            }
        );
        builder.addMatcher(
            warehouseApi.endpoints.getAvailableWarehouse.matchFulfilled,
            (state) => {
                state.loading = false;
            }
        );
        builder.addMatcher(
            warehouseApi.endpoints.getAvailableWarehouse.matchRejected,
            (state) => {
                state.loading = false;
            }
        );
        builder.addMatcher(
            inboundReplenishmentApi.endpoints.getMaterialsReferenceNumberLabels
                .matchFulfilled,
            (state, { payload }) => {
                state.referenceNumberLabels = payload.data;
            }
        );
        builder.addMatcher(
            inboundOrderByProductDefectiveApi.endpoints
                .getMaterialsReferenceNumberLabels.matchFulfilled,
            (state, { payload }) => {
                state.referenceNumberLabels = payload.data;
            }
        );
        builder.addMatcher(
            customerDeliveryOrderByProductApi.endpoints
                .getOrderReferenceNumberLabels.matchFulfilled,
            (state, { payload }) => {
                state.referenceNumberLabels = payload.data;
            }
        );
        builder.addMatcher(
            inboundReturnApi.endpoints.getOrderReferenceNumberLabels
                .matchFulfilled,
            (state, { payload }) => {
                state.referenceNumberLabels = payload.data;
            }
        );
        builder.addMatcher(
            outboundOrderByWarehouseApi.endpoints.getOrderReferenceNumberLabels
                .matchFulfilled,
            (state, { payload }) => {
                state.referenceNumberLabels = payload.data;
            }
        );
        builder.addMatcher(
            outboundOrderByWarehouseDefectiveApi.endpoints
                .getOrderReferenceNumberLabels.matchFulfilled,
            (state, { payload }) => {
                state.referenceNumberLabels = payload.data;
            }
        );
        builder.addMatcher(
            materialsTransferByWarehouseApi.endpoints.getAccountWarehouse
                .matchFulfilled,
            (state, { payload }) => {
                state.availableWarehouseList = payload.data.available;
                state.unavailableWarehouseList = payload.data.unavailable;
            }
        );
        builder.addMatcher(
            materialsTransferByWarehouseDefectiveApi.endpoints
                .getAccountWarehouse.matchFulfilled,
            (state, { payload }) => {
                state.availableWarehouseList = payload.data.available;
                state.unavailableWarehouseList = payload.data.unavailable;
            }
        );
        builder.addMatcher(
            inboundReplenishmentApi.endpoints.getAccountWarehouse
                .matchFulfilled,
            (state, { payload }) => {
                state.availableWarehouseList = payload.data.available;
                state.unavailableWarehouseList = payload.data.unavailable;
            }
        );
        builder.addMatcher(
            inboundOrderByProductDefectiveApi.endpoints.getAccountWarehouse
                .matchFulfilled,
            (state, { payload }) => {
                state.availableWarehouseList = payload.data.available;
                state.unavailableWarehouseList = payload.data.unavailable;
            }
        );
        builder.addMatcher(
            materialsTransferByWarehouseApi.endpoints
                .getOrderReferenceNumberLabels.matchFulfilled,
            (state, { payload }) => {
                state.referenceNumberLabels = payload.data;
            }
        );
        builder.addMatcher(
            materialsTransferByWarehouseDefectiveApi.endpoints
                .getOrderReferenceNumberLabels.matchFulfilled,
            (state, { payload }) => {
                state.referenceNumberLabels = payload.data;
            }
        );
    }
});

export const {
    resetOrderState,
    setRedirectBlocker,
    setActiveStep,
    setChosenProducts,
    editMainInfo,
    editProduct,
    removeProduct,
    addSelectedCountry,
    setFirstStepFormState,
    setChosenDelivery,
    setSecondStepFormState,
    setDelivery,
    resetDelivery,
    resetChosenDelivery,
    setAvailableWarehouseList,
    setUnavailableWarehouseList,
    setOrderSubmit,
    setThirdStepFormState,
    setForthStepFormState,
    removeSerial,
    setChosenWarehouse,
    resetSelectedCountry,
    setOrderType,
    resetChosenProducts,
    setShowUnexpectedError,
    setToggleFullWarehouseList
} = activeOrderSlice.actions;
