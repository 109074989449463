import { OrderType } from '@apis/trackOrders/TrackOrdersApi.types';

export enum ORDER_DATE_FILTER_TYPE {
    LATEST_EVENT = 'LatestMilestoneDate',
    ORDER_CREATION = 'CreateDate'
}

export const orderDateFilterTypesData: {
    value: ORDER_DATE_FILTER_TYPE;
    label: string;
}[] = [
    { value: ORDER_DATE_FILTER_TYPE.LATEST_EVENT, label: 'Latest Event' },
    { value: ORDER_DATE_FILTER_TYPE.ORDER_CREATION, label: 'Order Creation' }
];

export const productConditionTypes: OrderType[] = [
    {
        orderTypeId: 1,
        description: 'Good'
    },
    {
        orderTypeId: 2,
        description: 'Defective'
    }
];

export const deliveryTypes: OrderType[] = [
    {
        orderTypeId: 1,
        description: 'Domestic'
    },
    {
        orderTypeId: 2,
        description: 'International'
    }
];
