import { PreCheckResponse } from '@customTypes/api/api.types';
import {
    GetProductTransformedResponse,
    GetSerialsTransformedResponse
} from '@customTypes/general/product.types';
import { InitialPreCheckMaster } from '@store/slices/preCheckMaster/preCheckMaster';
import { InitialProductMaster } from '@store/slices/productMaster/productMaster';
import { InitialSerialsMaster } from '@store/slices/serialsMaster/serialsMaster';

type FulfilledActionType = {
    payload:
        | GetSerialsTransformedResponse
        | PreCheckResponse
        | GetProductTransformedResponse;
};

type FulfilledStateType =
    | InitialSerialsMaster
    | InitialPreCheckMaster
    | InitialProductMaster;

export const handleFulfilledPagination = (
    state: FulfilledStateType,
    action: FulfilledActionType
) => {
    const { payload } = action;

    state.pagination.totalItemsCount = payload.data?.totalItemsCount || 0;
    state.pagination.pagesCount = payload.data?.pagesCount || 0;
    state.data = payload.data?.items;
    state.errors = payload.data?.error;
};
