import { createApi } from '@reduxjs/toolkit/query/react';

import { REQUESTS_PATH } from '@apis/customerDeliveryOrderByProduct/CustomerDeliveryOrderByProductApi.types';
import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import {
    GetBasicDtoPagination,
    OrderReferenceLabelsResponse,
    PreCheckDTO,
    PreCheckResponse,
    SubmitFullOrderResponse,
    SubmitOrderDTO,
    SubmitReducedOrderResponse
} from '@customTypes/api/api.types';
import {
    AvailableServicesDTO,
    AvailableServicesResponse
} from '@customTypes/deliveryServices/deliveryServices.types';
import {
    GetProductTransformedResponse,
    GetSerialsTransformedResponse
} from '@customTypes/general/product.types';
import { OrderReferenceLabelsDTO } from '@customTypes/order/order.types';
import { getServicesWithIds } from '@helpers/activeOrder';
import {
    getSerialsQuery,
    getTransformedDataWithHazard,
    getTransformedSerialNumbersData
} from '@helpers/apiFormatter';
import { getBasePaginationBody } from '@helpers/getBasePaginationBody';

export const customerDeliveryOrderByProductApi = createApi({
    reducerPath: 'customerDeliveryOrderByProductApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getServices: builder.mutation<
            AvailableServicesResponse,
            AvailableServicesDTO
        >({
            query: (dto) => ({
                url: REQUESTS_PATH.GET_SERVICES,
                method: 'POST',
                body: dto
            }),
            transformResponse(response: AvailableServicesResponse) {
                return getServicesWithIds(
                    response
                ) as AvailableServicesResponse;
            }
        }),
        getOrderReferenceNumberLabels: builder.mutation<
            OrderReferenceLabelsResponse,
            OrderReferenceLabelsDTO
        >({
            query: (dto) => ({
                url: REQUESTS_PATH.ORDER_REFERENCE_LABELS,
                method: 'POST',
                body: dto
            })
        }),
        getCustomerOrderProducts: builder.mutation<
            GetProductTransformedResponse,
            GetBasicDtoPagination
        >({
            query: (dto) => {
                return {
                    url: REQUESTS_PATH.PRODUCTS_OUTBOUND,
                    method: 'POST',
                    body: getBasePaginationBody({
                        ...dto,
                        filterFields: 'CustomDescription',
                        fixedSorter: 'partNumber'
                    })
                };
            },
            extraOptions: {
                flowStart: true
            },
            transformResponse: getTransformedDataWithHazard
        }),
        getSerialNumbers: builder.mutation<
            GetSerialsTransformedResponse,
            GetBasicDtoPagination
        >({
            query: (dto) => getSerialsQuery(dto, REQUESTS_PATH.SERIAL_NUMBERS),
            transformResponse: getTransformedSerialNumbersData
        }),
        preCheckByProduct: builder.mutation<PreCheckResponse, PreCheckDTO>({
            query: (dto) => {
                return {
                    url: REQUESTS_PATH.PRE_CHECK,
                    method: 'POST',
                    body: dto
                };
            }
        }),
        submitFullOrderByProduct: builder.mutation<
            SubmitFullOrderResponse,
            SubmitOrderDTO
        >({
            query: (dto) => ({
                url: REQUESTS_PATH.SUBMIT_FULL_ORDER,
                method: 'POST',
                body: dto
            }),
            extraOptions: {
                flowEnd: true
            }
        }),
        submitReducedOrder: builder.mutation<
            SubmitReducedOrderResponse,
            SubmitOrderDTO
        >({
            query: (dto) => ({
                url: REQUESTS_PATH.SUBMIT_REDUCED_ORDER,
                method: 'POST',
                body: dto
            }),
            extraOptions: {
                flowEnd: true
            }
        })
    })
});

export const {
    usePreCheckByProductMutation,
    useGetOrderReferenceNumberLabelsMutation,
    useGetCustomerOrderProductsMutation,
    useGetServicesMutation,
    useGetSerialNumbersMutation,
    useSubmitFullOrderByProductMutation,
    useSubmitReducedOrderMutation
} = customerDeliveryOrderByProductApi;
