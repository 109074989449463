import React, { CSSProperties, ReactNode, useRef } from 'react';

import { Divider } from 'antd';
import cn from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';

import { Button } from '@atoms/button/Button';
import { Loader } from '@atoms/loader/Loader';
import { Popover } from '@atoms/popover/Popover';
import {
    GoToOrderDto,
    NotificationType
} from '@customTypes/notifications/notifications.type';
import { SvgNewNotification } from '@icons/NewNotification';
import { CardContainer } from '@organisms/notificationsPanel/shared/сardContainer/CardContainer';
import { useNotificationPanel } from '@organisms/notificationsPanel/useNotificationPanel';

import styles from './NotificationsPanel.module.less';

interface Props {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
    goToDetailsPage: (data: GoToOrderDto) => void;
    markAllAsRead: () => void;
    children: ReactNode;
    unreadCounter?: number;
}

export const NotificationsPanel = ({
    isOpen,
    setIsOpen,
    goToDetailsPage,
    markAllAsRead,
    children,
    unreadCounter
}: Props) => {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const topNotificationRef = useRef<NotificationType | null>(null);

    const {
        totalCount,
        notifications,
        allNotifications,
        allNotificationsLength,
        handleLoadMore,
        handleOpenChange,
        dateFormatKey,
        showLoader,
        isScrollToTopVisible,
        handleScrollToTop
    } = useNotificationPanel({
        isOpen,
        setIsOpen,
        scrollRef,
        topNotificationRef
    });

    const buttonStyle: CSSProperties = {
        position: 'fixed',
        top: scrollRef.current
            ? `${scrollRef.current.getBoundingClientRect().top + 20}px`
            : '20px',
        left: scrollRef.current
            ? `${
                  scrollRef.current.getBoundingClientRect().left +
                  scrollRef.current.offsetWidth / 2
              }px`
            : '50%'
    };

    const isMarkAllAsReadDisabled = !totalCount || unreadCounter === 0;

    return (
        <Popover
            className={styles.NotificationsPanel}
            overlayClass={styles.NotificationsPanelOverlay}
            align={{ offset: [70, 0] }}
            popoverContent={
                <div
                    data-testid="NotificationsPanelContent"
                    className={styles.Content}
                    ref={scrollRef}
                >
                    {showLoader ? (
                        <div className={styles.LoaderContainer}>
                            <Loader
                                className={styles.Loader}
                                key={0}
                                size="medium"
                            />
                        </div>
                    ) : (
                        <>
                            {allNotificationsLength ? (
                                <InfiniteScroll
                                    pageStart={0}
                                    useWindow={false}
                                    loadMore={handleLoadMore}
                                    hasMore={notifications.length < totalCount}
                                    loader={
                                        <Loader
                                            className={styles.Loader}
                                            key={0}
                                            size="medium"
                                        />
                                    }
                                >
                                    {allNotifications.map((item) => (
                                        <CardContainer
                                            key={item[0].userNotificationId}
                                            data={item}
                                            goToDetailsPage={goToDetailsPage}
                                            dateFormatKey={dateFormatKey}
                                        />
                                    ))}
                                </InfiniteScroll>
                            ) : (
                                <div className={styles.NoDataContainer}>
                                    <div data-testid="NoDataMessage">
                                        No Notifications
                                    </div>
                                </div>
                            )}
                            {isScrollToTopVisible && (
                                <Button
                                    theme={'primary'}
                                    endIcon={<SvgNewNotification />}
                                    onClick={handleScrollToTop}
                                    style={buttonStyle}
                                    text="New Notifications"
                                    className={styles.Content__NewNotification}
                                />
                            )}
                        </>
                    )}
                </div>
            }
            title={
                <div
                    data-testid="NotificationsPanelHeader"
                    className={styles.Header}
                >
                    <div className={styles.Header__Content}>
                        <div
                            data-testid="HeaderTitle"
                            className={styles.Header__Title}
                        >
                            Notifications
                        </div>
                        <Button
                            className={cn(styles.Header__Button, {
                                [styles.Header__Button__Disabled]:
                                    isMarkAllAsReadDisabled
                            })}
                            testId="MarkAllAsReadButton"
                            disabled={isMarkAllAsReadDisabled}
                            text="Mark All as Read"
                            theme="link"
                            onClick={markAllAsRead}
                        />
                    </div>
                    <Divider className={styles.Divider} />
                </div>
            }
            showArrow={false}
            showIcon={false}
            placement="bottom"
            trigger="click"
            visible={isOpen}
            onVisibleChange={handleOpenChange}
            triggerContent={
                <div className={styles.TriggerContent}>{children}</div>
            }
            destroyTooltipOnHide={true}
        />
    );
};
