import {
    MENU_SECTIONS_PERMISSION_KEYS,
    PERMISSIONS_KEYS
} from '@constants/permissionsKeys';

export const RoutesList = {
    ROOT: '/',
    NOT_FOUND: '/not-found',
    AUTH: {
        ROOT: 'auth',
        SIGN_UP: 'signup',
        VERIFICATION: 'verification',
        CREATE_PASS: 'create-password'
    },
    NOT_ALLOWED: 'not-allowed',
    SETTINGS: 'user-settings',
    HELP_CENTER: 'help-center',
    TRACK_ORDERS: {
        ROOT: 'track-orders',
        ORDER_DETAILS: {
            VIEW: ':id',
            VIEW_FULL: 'track-orders/:id'
        }
    },
    HOME: {
        ROOT: 'home'
    },
    ORDERS: {
        ROOT: 'customer-delivery',
        BY_PART: {
            CREATE: 'good-products',
            CREATE_FULL: 'customer-delivery/good-products'
        },
        BULK: {
            CREATE: 'good-products-upload',
            CREATE_FULL: 'customer-delivery/good-products-upload'
        }
    },
    REPLENISHMENTS: {
        ROOT: 'replenishments',
        GOOD: {
            CREATE: 'good-products',
            CREATE_FULL: 'replenishments/good-products'
        },
        BULK: {
            CREATE: 'good-products-upload',
            CREATE_FULL: 'replenishments/good-products-upload'
        }
    },
    TRANSFERS: {
        ROOT: 'warehouse-transfers',
        GOOD: {
            CREATE: 'good-products',
            CREATE_FULL: 'warehouse-transfers/good-products'
        },
        UPLOAD: {
            CREATE: 'good-products-upload',
            CREATE_FULL: 'warehouse-transfers/good-products-upload'
        },
        DEFECTIVE: {
            CREATE: 'defective-products',
            CREATE_FULL: 'warehouse-transfers/defective-products'
        },
        UPLOAD_DEFECTIVE: {
            CREATE: 'defective-products-upload',
            CREATE_FULL: 'warehouse-transfers/defective-products-upload'
        }
    },

    RETURNS: {
        ROOT: 'returns',
        DEFECTIVE_TO_CHOICE: {
            CREATE: 'defective-to-choice',
            CREATE_FULL: 'returns/defective-to-choice'
        },
        DEFECTIVE_TO_CHOICE_UPLOAD: {
            CREATE: 'defective-to-choice-upload',
            CREATE_FULL: 'returns/defective-to-choice-upload'
        },
        GOOD_TO_CHOICE: {
            CREATE: 'good-to-choice',
            CREATE_FULL: 'returns/good-to-choice'
        },
        DEFECTIVE_FROM_CHOICE: {
            CREATE: 'defective-from-choice',
            CREATE_FULL: 'returns/defective-from-choice'
        },
        DEFECTIVE_FROM_CHOICE_UPLOAD: {
            CREATE: 'defective-from-choice-upload',
            CREATE_FULL: 'returns/defective-from-choice-upload'
        },
        GOOD_FROM_CHOICE: {
            CREATE: 'good-from-choice',
            CREATE_FULL: 'returns/good-from-choice'
        }
    },

    ANALYTICS: {
        ROOT: 'analytics',
        CLIENT_SPEND: {
            VIEW: 'client-spend',
            VIEW_FULL: 'analytics/client-spend'
        },
        DUTIES_TAXES_ANALYSIS: {
            VIEW: 'duties-taxes-analysis',
            VIEW_FULL: 'analytics/duties-taxes-analysis'
        },
        ORDER_HISTORY: {
            READ: 'order-history',
            READ_FULL: 'analytics/order-history'
        },
        CYCLE_COUNT_PERFORMANCE: {
            READ: 'cycle-count-performance',
            READ_FULL: 'analytics/cycle-count-performance'
        },
        GLOBAL_CAPABILITIES: {
            READ: 'global-capabilities',
            READ_FULL: 'analytics/global-capabilities'
        },
        DOCK_TO_STOCK: {
            READ: 'dock-to-stock',
            READ_FULL: 'analytics/dock-to-stock'
        },
        OUTBOUND_DRIVE_FILL_RATE: {
            READ: 'outbound-drive-fill-rate',
            READ_FULL: 'analytics/outbound-drive-fill-rate'
        },
        OUTBOUND_DRIVE_PERFORMANCE: {
            READ: 'outbound-drive-performance',
            READ_FULL: 'analytics/outbound-drive-performance'
        },
        TENDER_TO_CARRIER_PERFORMANCE: {
            READ: 'tender-to-carrier-performance',
            READ_FULL: 'analytics/tender-to-carrier-performance'
        }
    },
    MANAGE_INVENTORY: {
        ROOT: 'manage-inventory',
        PART_MASTER: {
            READ: 'product-master',
            READ_FULL: 'manage-inventory/product-master'
        },
        SUBSTITUTE_PARTS: {
            READ: 'substitute-products',
            READ_FULL: 'manage-inventory/substitute-products'
        },
        MATERIALS_REVIEW: {
            ROOT: 'material-in-review',
            FULL: 'manage-inventory/material-in-review',
            MIR_DETAILS: {
                VIEW: ':id',
                VIEW_FULL: 'manage-inventory/material-in-review/:id'
            }
        },
        MATERIALS_IN_REVIEW_ANALYSIS: {
            ROOT: 'material-in-review-analysis',
            FULL: 'manage-inventory/material-in-review-analysis'
        },
        INVENTORY_ON_HAND: {
            ROOT: 'inventory-on-hand',
            FULL: 'manage-inventory/inventory-on-hand'
        },
        LOCATE_CLOSEST_WAREHOUSE: {
            ROOT: 'locate-closest-warehouse',
            FULL: 'manage-inventory/locate-closest-warehouse'
        },
        MISSING_PARTS: {
            ROOT: 'missing-products',
            FULL: 'manage-inventory/missing-products'
        },
        INVENTORY_TRANSACTION_HISTORY: {
            ROOT: 'inventory-transaction-history',
            FULL: 'manage-inventory/inventory-transaction-history'
        }
    },
    BILLING: {
        ROOT: 'billing',
        INVOICES: {
            VIEW: 'invoices',
            VIEW_FULL: 'billing/invoices'
        },
        STORAGE_BILLING: {
            VIEW: 'storage-billing',
            VIEW_FULL: 'billing/storage-billing'
        },
        INVOICE_BILLING: {
            VIEW: 'invoice-billing',
            VIEW_FULL: 'billing/invoice-billing'
        }
    },
    ADMIN_PANEL: {
        ROOT: 'administration',
        ADMINS: {
            READ: {
                ROOT: 'admins',
                FULL: 'administration/admins'
            },
            CREATE: {
                ROOT: 'new-admin',
                FULL: 'administration/admins/new-admin'
            },
            EDIT: {
                ROOT: 'edit-admin',
                FULL: 'administration/admins/edit-admin'
            }
        },
        USERS: {
            READ: {
                ROOT: 'users',
                FULL: 'administration/users'
            },
            CREATE: {
                ROOT: 'new-user',
                FULL: 'administration/users/new-user'
            },
            EDIT: {
                ROOT: 'edit-user',
                FULL: 'administration/users/edit-user'
            }
        },
        PROFILES: {
            READ: {
                ROOT: 'profiles',
                FULL: 'administration/profiles'
            },
            CREATE: {
                ROOT: 'new-profile',
                FULL: 'administration/profiles/new-profile'
            },
            EDIT: {
                ROOT: 'edit-profile',
                FULL: 'administration/profiles/edit-profile'
            },
            VIEW: {
                ROOT: 'view-profile',
                FULL: 'administration/profiles/view-profile'
            }
        },
        CHOICE_ADMIN: {
            READ: {
                ROOT: 'choice-administrators',
                FULL: 'administration/choice-administrators'
            },
            CREATE: {
                ROOT: 'new-administrator',
                FULL: 'administration/choice-administrators/new-administrator'
            },
            EDIT: {
                ROOT: 'edit-administrator',
                FULL: 'administration/choice-administrators/edit-administrator'
            }
        }
    }
};

export const NavigationKeys = {
    home: {
        key: 'home',
        permissions: MENU_SECTIONS_PERMISSION_KEYS.home
    },
    trackOrders: {
        key: 'trackOrders',
        permissions: MENU_SECTIONS_PERMISSION_KEYS.trackOrders
    },
    customerDelivery: {
        key: 'customerDelivery',
        permission: MENU_SECTIONS_PERMISSION_KEYS.customerDelivery,
        goodByPart: {
            key: 'customerDeliveryGood',
            permission: PERMISSIONS_KEYS.customerDeliveryGood
        },
        goodByUpload: {
            key: 'customerDeliveryGoodUpload',
            permission: PERMISSIONS_KEYS.customerDeliveryGoodProductsUpload
        }
    },
    replenishments: {
        key: 'replenishments',
        permission: MENU_SECTIONS_PERMISSION_KEYS.replenishments,
        inboundReplenishment: {
            key: 'replenishmentsGood',
            permission: PERMISSIONS_KEYS.replenishmentGoodProducts
        },
        inboundGoodByUpload: {
            key: 'replenishmentGoodUpload',
            permission: PERMISSIONS_KEYS.replenishmentGoodProductsUpload
        }
    },
    createTransfer: {
        key: 'createTransfer',
        permission: MENU_SECTIONS_PERMISSION_KEYS.createTransfer,
        transferGoodByWarehouse: {
            key: 'warehouseTransferGood',
            permission: PERMISSIONS_KEYS.warehouseTransferGood
        },
        transferGoodByUpload: {
            key: 'warehouseTransferGoodUpload',
            permission: PERMISSIONS_KEYS.warehouseTransferGoodProductsUpload
        },
        transferDefectiveByWarehouse: {
            key: 'warehouseTransferDefective',
            permission: PERMISSIONS_KEYS.warehouseTransferDefectiveProducts
        },
        transferDefectiveByUpload: {
            key: 'warehouseTransferDefectiveUpload',
            permission:
                PERMISSIONS_KEYS.warehouseTransferDefectiveProductsUpload
        }
    },
    returnParts: {
        key: 'returnParts',
        permission: MENU_SECTIONS_PERMISSION_KEYS.returnProducts,
        defectiveToChoice: {
            key: 'returnPartsDefectiveToChoice',
            permission: PERMISSIONS_KEYS.returnDefectiveToChoice
        },
        defectiveToChoiceUpload: {
            key: 'returnDefectiveToChoiceUpload',
            permission: PERMISSIONS_KEYS.returnDefectiveToChoiceUpload
        },
        goodToChoice: {
            key: 'returnGoodToChoice',
            permission: PERMISSIONS_KEYS.returnGoodToChoice
        },
        defectiveFromChoice: {
            key: 'returnDefectiveFromChoice',
            permission: PERMISSIONS_KEYS.returnDefectiveFromChoice
        },
        defectiveFromChoiceUpload: {
            key: 'returnDefectiveFromChoiceUpload',
            permission: PERMISSIONS_KEYS.returnDefectiveFromChoiceUpload
        },
        goodFromChoice: {
            key: 'returnGoodFromChoice',
            permission: PERMISSIONS_KEYS.returnGoodFromChoice
        }
    },

    manageInventory: {
        key: 'manageInventory',
        permission: MENU_SECTIONS_PERMISSION_KEYS.manageInventory,
        inventoryOnHand: {
            key: 'inventoryOnHand',
            permission: PERMISSIONS_KEYS.inventoryOnHand
        },
        partMaster: {
            key: 'partMaster',
            permission: PERMISSIONS_KEYS.productMaster
        },
        substituteParts: {
            key: 'substituteParts',
            permission: PERMISSIONS_KEYS.substituteProducts
        },
        inventoryTransactionHistory: {
            key: 'inventoryTransactionHistory',
            permission: PERMISSIONS_KEYS.inventoryTransactionHistory
        },
        materialInReview: {
            key: 'materialInReview',
            permission: PERMISSIONS_KEYS.materialInReview
        },
        materialInReviewAnalysis: {
            key: 'materialInReviewAnalysis',
            permission: PERMISSIONS_KEYS.materialInReviewAnalysis
        },
        missingParts: {
            key: 'missingParts',
            permission: PERMISSIONS_KEYS.missingProduct
        },
        locateClosestWarehouse: {
            key: 'locateClosestWarehouse',
            permission: PERMISSIONS_KEYS.locateClosestWarehouse
        }
    },
    billing: {
        key: 'billing',
        permission: MENU_SECTIONS_PERMISSION_KEYS.financials,
        invoices: {
            key: 'invoices',
            permission: PERMISSIONS_KEYS.invoices
        },
        storageBilling: {
            key: 'storageBilling',
            permission: PERMISSIONS_KEYS.storageBilling
        },
        invoiceBilling: {
            key: 'invoiceBilling',
            permission: PERMISSIONS_KEYS.clientBilling
        }
    },
    analytics: {
        key: 'analytics',
        permission: MENU_SECTIONS_PERMISSION_KEYS.analytics,
        orderHistory: {
            key: 'orderHistory',
            permission: PERMISSIONS_KEYS.analyticsOrderHistory
        },
        dutiesTaxesAnalysis: {
            key: 'dutiesTaxesAnalysis',
            permission: PERMISSIONS_KEYS.dutiesTaxesAnalysis
        },
        spendAnalysis: {
            key: 'spendAnalysis',
            permission: PERMISSIONS_KEYS.clientSpend
        },
        cycleCountPerformance: {
            key: 'cycleCountPerformance',
            permission: PERMISSIONS_KEYS.cycleCountPerformance
        },
        globalCapabilities: {
            key: 'globalCapabilities',
            permission: PERMISSIONS_KEYS.globalCapabilities
        },
        dockToStock: {
            key: 'dockToStock',
            permission: PERMISSIONS_KEYS.dockToStock
        },
        outboundDriveFillRate: {
            key: 'outboundDriveFillRate',
            permission: PERMISSIONS_KEYS.outboundDriveFillRate
        },
        outboundDrivePerformance: {
            key: 'outboundDrivePerformance',
            permission: PERMISSIONS_KEYS.outboundDrivePerformance
        },
        tenderToCarrierPerformance: {
            key: 'tenderToCarrierPerformance',
            permission: PERMISSIONS_KEYS.tenderToCarrierPerformance
        }
    },
    adminPanel: {
        admins: {
            key: 'admins',
            permission: PERMISSIONS_KEYS.admins
        },
        users: {
            key: 'users',
            permission: PERMISSIONS_KEYS.users
        },
        profiles: {
            key: 'profiles',
            permission: PERMISSIONS_KEYS.profiles
        },
        choiceAdmins: {
            key: 'choiceAdmins',
            permission: PERMISSIONS_KEYS.choiceAdmins
        }
    }
};
