import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
    useGetNotificationsMutation,
    useLazyGetUnreadCountQuery,
    useReadAllNotificationsMutation,
    useReadSingleNotificationMutation
} from '@apis/notifications/Notifications.api';
import { isMobileAndTablet } from '@constants/breakpoints';
import { RoutesList } from '@constants/routesList';
import { GoToOrderDto } from '@customTypes/notifications/notifications.type';
import { ModalData } from '@hooks/useModal';
import { useWindowDimentions } from '@hooks/useWindowDimentions';
import { useRedirectChecker } from '@redirect/useRedirectChecker';
import { useSignalR } from '@signalR/SignalR';
import { RECEIVE_EVENTS, HUBS } from '@signalR/types';
import { useAppDispatch } from '@store/index';
import { resetNotificationsState } from '@store/slices/notifications/notifications';

const DEFAULT_REQUEST_PAYLOAD = {
    sorts: '-CreatedDate',
    page: 1,
    pageSize: 5
};

interface Ars {
    redirectModalToggle: VoidFunction;
    setRedirectPath: Dispatch<SetStateAction<string>>;
    setModalData: (data?: ModalData<{ cb?: VoidFunction }>) => void;
}

export const useNotifications = ({
    setModalData,
    redirectModalToggle,
    setRedirectPath
}: Ars) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const blockRedirectCondition = useRedirectChecker();

    const { width } = useWindowDimentions();
    const isMobileVersion = isMobileAndTablet(width);

    const [isOpen, setIsOpen] = useState(false);
    const [getUnreadCount] = useLazyGetUnreadCountQuery();
    const [readAllNotifications] = useReadAllNotificationsMutation();
    const [readSingleNotification] = useReadSingleNotificationMutation();
    const [getNotifications] = useGetNotificationsMutation();

    const [unreadCounter, setUnreadCounter] = useState<number | undefined>(
        undefined
    );

    const { openConnection } = useSignalR<number>({
        hub: HUBS.user.notificationCount,
        eventCallBack: (unreadCount) => {
            setUnreadCounter(unreadCount);
        },
        eventName:
            RECEIVE_EVENTS.RECEIVE_UNREAD_ORDER_STATUS_NOTIFICATIONS_COUNT
    });

    useEffect(() => {
        getUnreadCount()
            .unwrap()
            .then((unreadCountResponse) => {
                setUnreadCounter(unreadCountResponse.data);
            });
        void openConnection();
    }, []);

    const markSingleNotificationAsRead = (
        notificationId: string,
        isRead: boolean
    ) => {
        readSingleNotification(notificationId.toString());
        if (unreadCounter && !isRead) {
            setUnreadCounter(unreadCounter - 1);
        }
    };

    const goToDetailsPage = ({
        orderId,
        notificationId,
        isRead,
        proNum
    }: GoToOrderDto) => {
        setIsOpen(false);
        const notificationsCallback = () => {
            dispatch(resetNotificationsState());
            markSingleNotificationAsRead(notificationId.toString(), isRead);
        };

        const path = RoutesList.TRACK_ORDERS.ORDER_DETAILS.VIEW_FULL.replace(
            ':id',
            String(orderId)
        );

        if (blockRedirectCondition) {
            redirectModalToggle();
            setRedirectPath(path);
            setModalData({
                cb: notificationsCallback
            });
        } else {
            notificationsCallback();
            navigate(`/${path}`, {
                state: { proNum }
            });
        }
    };

    const markAllAsRead = async () => {
        await readAllNotifications();
        dispatch(resetNotificationsState());
        await getNotifications(DEFAULT_REQUEST_PAYLOAD);
        setUnreadCounter(0);
    };

    return {
        isOpen,
        setIsOpen,
        goToDetailsPage,
        markAllAsRead,
        unreadCounter,
        isMobileVersion
    };
};
