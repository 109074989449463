import React, { ReactNode } from 'react';

import { SorterResult as SortedData } from 'antd/es/table/interface';

import { GetIOHProductsDTO } from 'APIServices/warehouse/WarehouseApi.types';
import { Error, GetBasicDtoPagination } from 'utils/types/api/api.types';
import {
    InboundBulkStepData,
    BulkFormValues
} from 'utils/types/bulkOrder/bulk.types';
import { StepperFormValues } from 'utils/types/general/stepper.types';
import { InboundStepData } from 'utils/types/materials/replenishment.types';
import {
    OutboundOrderFormValues,
    OutboundOrderStepData
} from 'utils/types/order/order.types';

export interface InitialReducerState {
    loading: boolean;
    errors?: Error;
}

export type VoidFunction = () => void;
export type AsyncFunction = () => Promise<void>;

export enum SORTING_DIRECTION {
    DESC = 'descend',
    ASC = 'ascend'
}

export interface Sorting {
    field: string;
    direction: SORTING_DIRECTION;
}

export interface Pagination {
    page: number;
    pageSize: number;
    pagesCount: number;
    totalItemsCount: number;
}

export interface PerPageOptions {
    onChangePerPage: (value: string) => void;
    perPageValue: number;
}

export type SetStateAction<T> = React.Dispatch<React.SetStateAction<T>>;

export enum PeriodVariants {
    AM = 'AM',
    PM = 'PM'
}

export type Period = keyof typeof PeriodVariants;

export type SorterResult<T> = SortedData<T> | SortedData<T>[];

export type DtoTypes = GetBasicDtoPagination | GetIOHProductsDTO;

export interface TreeChildrenType {
    id: number;
    name: string;
}

export type TreeNode = {
    [key in string]: TreeChildrenType[];
};

export interface StepType {
    stepNumber: number;
    title: string;
}

export interface BaseStepData {
    title?: string;
    description?: string;
    onPreviousClick?: VoidFunction;
    onNextClick?: VoidFunction;
    prevStep?: VoidFunction;
    content: ReactNode;
    formName?: string;
    disableNext?: boolean;
    loadingNext?: boolean;
    summaryData?: ReactNode;
    headAdditionalContent?: ReactNode;
}

export type StepData =
    | OutboundOrderStepData
    | InboundStepData
    | InboundBulkStepData;

export type FinishStepValues = OutboundOrderFormValues &
    StepperFormValues &
    BulkFormValues;

export interface Country {
    countryId: number;
    countryCode: string;
    countryName: string;
    internationalCallingCode: string;
    postalCodeRegEx: string | null;
    hasPostalCodes: boolean;
}

export interface LocationType {
    countryName: string;
    countryCode?: string;
    city?: string;
    postalCode?: string;
    state?: string;
}

export const defaultSortDirections = [
    SORTING_DIRECTION.ASC,
    SORTING_DIRECTION.DESC,
    SORTING_DIRECTION.ASC
];

export const defaultMirNumSortDirections = [
    SORTING_DIRECTION.DESC,
    SORTING_DIRECTION.ASC,
    SORTING_DIRECTION.DESC
];

export interface HeaderActions {
    signOut: VoidFunction;
}

export interface PaginationReturnType<T> {
    perPageOptions?: {
        onChangePerPage: (value: string) => void;
        perPageValue: number;
    };
    onPageChange: (newPage: number) => void;
    currentPageTableData: T[];
    pagesCount: number;
    currentPage: number;
    pageSize: number;
    totalItemsCount: number;
}

export interface ListItem {
    label: string;
    value: string;
}

export type ListRefNumbers = (ListItem | undefined | null)[];

export enum FILE_EXTENSIONS {
    XLSX = 'xlsx',
    CSV = 'csv'
}

export type NullableDate = Date | null;

export interface Status {
    label: string;
    value: number;
}
