import { FormInstance } from 'antd';
import uniqueId from 'lodash/uniqueId';
import { useDispatch } from 'react-redux';

import {
    AvailableServicesDataType,
    GetAvailableServicesResponse
} from '@customTypes/bulkOrder/bulk.types';
import {
    AvailableServices,
    AvailableServicesResponse,
    DELIVERY_TRANSPORT_TYPES,
    Services
} from '@customTypes/deliveryServices/deliveryServices.types';
import { ProductFormValue } from '@customTypes/general/product.types';
import { ACTIVE_ORDER_TYPE } from '@customTypes/order/order.types';
import {
    editMainInfo,
    setChosenProducts,
    setThirdStepFormState
} from '@store/slices/activeOrder/activeOrder';

const outboundOrders = [
    ACTIVE_ORDER_TYPE.GOOD_BY_PRODUCT,
    ACTIVE_ORDER_TYPE.GOOD_BY_WAREHOUSE,
    ACTIVE_ORDER_TYPE.DEFECTIVE_BY_WAREHOUSE
];

export const checkIsOutboundOrder = (orderType?: ACTIVE_ORDER_TYPE) =>
    !!orderType && outboundOrders.includes(orderType);

export const resetThirdStepHelper = ({
    chosenProducts,
    form,
    isMandatoryRef2,
    itemRef,
    dispatch
}: {
    chosenProducts: ProductFormValue[];
    form: FormInstance;
    isMandatoryRef2?: boolean;
    itemRef?: boolean;
    dispatch: ReturnType<typeof useDispatch>;
}) => {
    const productsWithoutReferences = chosenProducts.map((product) => {
        form.resetFields([`referenceNumbers__${product.partId}`]);
        form.resetFields([`serials__${product.partId}`]);
        return {
            ...product,
            referenceNumbers: undefined,
            serials: []
        };
    });
    const isOrderRefsCompleted = isMandatoryRef2
        ? Boolean(
              form.getFieldValue('orderReferenceNumber') &&
                  form.getFieldValue('orderReferenceNumber2')
          )
        : Boolean(form.getFieldValue('orderReferenceNumber'));

    dispatch(
        setChosenProducts({
            products: productsWithoutReferences
        })
    );

    dispatch(
        editMainInfo({
            products: productsWithoutReferences
        })
    );
    dispatch(
        setThirdStepFormState(
            !itemRef && isOrderRefsCompleted ? isOrderRefsCompleted : false
        )
    );
};

const setServiceId = (option?: null | Services): Services => {
    if (!option) return {} as Services;
    const data = Object.entries(option);

    return data.reduce((acc, item) => {
        return item[1]
            ? {
                  ...acc,
                  [item[0]]: item[1].map((el) => ({
                      ...el,
                      id: uniqueId()
                  }))
              }
            : acc;
    }, {} as Services);
};

export const getAllOrderServices = (
    data?: AvailableServices | AvailableServicesDataType
) => {
    const immediateService = data?.immediate
        ? Object.values(data?.immediate)
        : [];
    const scheduledService = data?.scheduled
        ? Object.values(data?.scheduled)
        : [];

    const willCallService = data?.willCall ? Object.values(data?.willCall) : [];

    return [...immediateService, ...scheduledService, ...willCallService];
};

export const getServicesWithIds = (
    response: AvailableServicesResponse | GetAvailableServicesResponse
) => {
    const servicesAreEmpty = getAllOrderServices(response.data).every(
        (item) => !item || !item.length
    );

    if (servicesAreEmpty) return response;

    const immediate = setServiceId(response.data?.immediate);
    const scheduled = setServiceId(response.data?.scheduled);
    const willCall = setServiceId(response.data?.willCall);

    return {
        ...response,
        data: {
            ...response.data,
            immediate,
            scheduled,
            willCall
        }
    };
};

export const getServiceByType = (
    sectionName: string,
    data?: Record<'willCall' | 'scheduled' | 'immediate', Services>
) => {
    const willCallService = data?.willCall;
    const scheduledService = data?.scheduled;
    const immediateService = data?.immediate;

    switch (sectionName) {
        case DELIVERY_TRANSPORT_TYPES.Immediate.toLowerCase():
            return immediateService;
        case DELIVERY_TRANSPORT_TYPES.Carrier.toLowerCase():
            return scheduledService;
        default:
            return willCallService;
    }
};
