import { createSlice } from '@reduxjs/toolkit';

import { customerDeliveryOrderByProductApi } from '@apis/customerDeliveryOrderByProduct/CustomerDeliveryOrderByProductApi';
import {
    materialsTransferByWarehouseApi,
    materialsTransferByWarehouseDefectiveApi
} from '@apis/materialsTransferByWarehouse/MaterialsTransferByWarehouse.api';
import {
    outboundOrderByWarehouseApi,
    outboundOrderByWarehouseDefectiveApi
} from '@apis/outboundOrderByWarehouse/OutboundOrderByWarehouse.api';
import { initialPaginationState } from '@constants/pagination';
import { MasterInitialState } from '@customTypes/api/api.types';
import { Pagination } from '@customTypes/general/general.types';
import { GetSerialsTransformedResponse } from '@customTypes/general/product.types';
import { handleFulfilledPagination } from '@helpers/handleFulfilledPagination';
import { Action } from 'store/index';

export interface InitialSerialsMaster extends MasterInitialState {
    data: GetSerialsTransformedResponse['data']['items'];
    activeProductNumber: string;
}

const initialState: InitialSerialsMaster = {
    loading: false,
    data: [],
    activeProductNumber: '',
    pagination: initialPaginationState,
    errors: { code: 0, message: '' }
};

export const serialsMasterSlice = createSlice({
    name: 'serialsMaster',
    initialState,
    reducers: {
        editPagination(
            state,
            action: Action<
                Partial<Omit<Pagination, 'pagesCount' | 'totalItemsCount'>>
            >
        ) {
            state.pagination = {
                ...state.pagination,
                ...action.payload
            };
        },
        setActiveProductNumber(state, { payload }: Action<string>) {
            state.activeProductNumber = payload;
        },
        resetSerialsMaster() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            customerDeliveryOrderByProductApi.endpoints.getSerialNumbers
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            outboundOrderByWarehouseApi.endpoints.getSerialNumbers
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            outboundOrderByWarehouseDefectiveApi.endpoints.getSerialNumbers
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            materialsTransferByWarehouseApi.endpoints.getSerialNumbers
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            materialsTransferByWarehouseDefectiveApi.endpoints.getSerialNumbers
                .matchFulfilled,
            handleFulfilledPagination
        );
    }
});

export const { editPagination, setActiveProductNumber, resetSerialsMaster } =
    serialsMasterSlice.actions;
