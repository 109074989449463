import React, { Dispatch, SetStateAction } from 'react';

import classNames from 'classnames';

import { ModalData } from '@hooks/useModal';
import { useNotifications } from '@organisms/notifications/useNotifications';
import { SvgBellDark, SvgBellLight } from 'components/icons';
import { Badge } from 'components/UI/atoms/badge/Badge';
import { NotificationsPanel } from 'components/UI/organisms/notificationsPanel/NotificationsPanel';

import styles from './Notifications.module.less';

interface Props {
    redirectModalToggle: VoidFunction;
    setRedirectPath: Dispatch<SetStateAction<string>>;
    setModalData: (data?: ModalData<{ cb?: VoidFunction }>) => void;
}

export const Notifications = ({
    redirectModalToggle,
    setRedirectPath,
    setModalData
}: Props) => {
    const {
        isOpen,
        setIsOpen,
        goToDetailsPage,
        markAllAsRead,
        unreadCounter,
        isMobileVersion
    } = useNotifications({
        redirectModalToggle,
        setRedirectPath,
        setModalData
    });

    return (
        <div
            className={styles.NotificationsContainer}
            data-testid="notificationsContainer"
        >
            <NotificationsPanel
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                goToDetailsPage={goToDetailsPage}
                markAllAsRead={markAllAsRead}
                unreadCounter={unreadCounter}
            >
                <div
                    id="badge"
                    data-testid="counterBadge"
                    className={classNames(styles.Row, {
                        [styles.Badge__Empty]: !unreadCounter
                    })}
                >
                    <Badge
                        offset={[-4, 10]}
                        overflowCount={999}
                        count={unreadCounter}
                        node={
                            isMobileVersion ? <SvgBellLight /> : <SvgBellDark />
                        }
                    />
                </div>
            </NotificationsPanel>
        </div>
    );
};
