import { BaseStepData } from 'utils/types/general/general.types';
import {
    DeliveryStepFormValues,
    ProductQuantity,
    SerialsStepFormValues
} from 'utils/types/general/stepper.types';

export enum TRANSFER_WAREHOUSE_STEPS {
    SELECT_PARTS = 'Select Products',
    DELIVERY = 'Delivery',
    REFERENCE_NUMBERS = 'Reference Numbers',
    REVIEW = 'Order Review'
}

export type TransferByWarehouseFormValues =
    SelectProductByWarehouseStepFormValues &
        DeliveryStepFormValues &
        SerialsStepFormValues;

export interface TransferByWarehouseStepData extends BaseStepData {
    initialValues?: Partial<TransferByWarehouseFormValues>;
    onFinish?: (values: TransferByWarehouseFormValues) => void;
}

export interface SelectProductByWarehouseStepFormValues
    extends ProductQuantity {
    warehouse: string;
}
