import React from 'react';

import { ProductCount } from '@apis/trackOrders/TrackOrdersApi.types';
import { ToolTip } from '@atoms/tooltip/ToolTip';
import { capitalize } from '@helpers/stringHelper';
import styles from '@organisms/trackOrders/orderList/shared/productsCount/ProductsCount.module.less';

interface Props {
    partsCount: ProductCount;
    isMobile?: boolean;
}

export const ProductsCount = ({ partsCount, isMobile }: Props) => {
    const totalCount = partsCount.total;

    const tooltipText = Object.entries(partsCount)
        .map(([key, value]) => {
            return value && key !== 'total'
                ? `${value} ${capitalize(key === 'bad' ? 'defective' : key)}`
                : '';
        })
        .filter(Boolean)
        .join(',');

    return (
        <span className={styles.Text}>
            <ToolTip
                title={tooltipText}
                overlayClassName={styles.Tooltip}
                placement={isMobile ? 'bottomRight' : 'bottomLeft'}
            >
                {totalCount}
            </ToolTip>
        </span>
    );
};
