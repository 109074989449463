import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import {
    GetAvailableReturnWarehouses,
    REQUESTS_PATH
} from '@apis/inboundReturn/InboundReturn.type';
import {
    GetWarehouseResponse,
    GetWarehouseTransformedResponse
} from '@apis/warehouse/WarehouseApi.types';
import {
    GetBasicDtoPagination,
    OrderReferenceLabelsResponse,
    SubmitFullOrderResponse,
    SubmitOrderDTO
} from '@customTypes/api/api.types';
import {
    AvailableConsignorServicesDTO,
    AvailableServicesResponse
} from '@customTypes/deliveryServices/deliveryServices.types';
import { GetProductTransformedResponse } from '@customTypes/general/product.types';
import { OrderReferenceLabelsDTO } from '@customTypes/order/order.types';
import { getServicesWithIds } from '@helpers/activeOrder';
import { getTransformedDataWithHazard } from '@helpers/apiFormatter';
import { getBasePaginationBody } from '@helpers/getBasePaginationBody';

export const inboundReturnApi = createApi({
    reducerPath: 'inboundReturnApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getProducts: builder.mutation<
            GetProductTransformedResponse,
            GetBasicDtoPagination
        >({
            query: (dto) => {
                return {
                    url: REQUESTS_PATH.PARTS,
                    method: 'POST',
                    body: getBasePaginationBody({
                        ...dto,
                        filterFields: 'CustomDescription',
                        fixedSorter: 'partNumber'
                    })
                };
            },
            extraOptions: {
                flowStart: true
            },
            transformResponse: getTransformedDataWithHazard
        }),
        getAccountWarehouse: builder.mutation<
            GetWarehouseTransformedResponse,
            GetAvailableReturnWarehouses
        >({
            query(dto) {
                return {
                    url: REQUESTS_PATH.ACCOUNT_WAREHOUSE,
                    method: 'POST',
                    body: dto
                };
            },
            transformResponse: (
                response: GetWarehouseResponse
            ): GetWarehouseTransformedResponse => {
                return {
                    ...response,
                    data: {
                        available: response.data?.available?.map((item) => ({
                            ...item,
                            id: item.sslId.toString()
                        })),
                        unavailable: response.data?.unavailable?.map(
                            (item) => ({
                                ...item,
                                id: item.sslId.toString()
                            })
                        )
                    }
                };
            }
        }),
        getServices: builder.mutation<
            AvailableServicesResponse,
            AvailableConsignorServicesDTO
        >({
            query(dto) {
                return {
                    url: REQUESTS_PATH.GET_SERVICES,
                    method: 'POST',
                    body: dto
                };
            },
            transformResponse(response: AvailableServicesResponse) {
                return getServicesWithIds(
                    response
                ) as AvailableServicesResponse;
            }
        }),
        submitInboundReturn: builder.mutation<
            SubmitFullOrderResponse,
            SubmitOrderDTO
        >({
            query: (dto) => ({
                url: REQUESTS_PATH.SUBMIT,
                method: 'POST',
                body: dto
            }),
            extraOptions: {
                flowEnd: true
            }
        }),
        getOrderReferenceNumberLabels: builder.mutation<
            OrderReferenceLabelsResponse,
            OrderReferenceLabelsDTO
        >({
            query: (dto) => ({
                url: REQUESTS_PATH.REFERENCE_LABELS,
                method: 'POST',
                body: dto
            })
        })
    })
});

export const {
    useGetProductsMutation,
    useGetAccountWarehouseMutation,
    useGetServicesMutation,
    useGetOrderReferenceNumberLabelsMutation,
    useSubmitInboundReturnMutation
} = inboundReturnApi;
