import React from 'react';

import { Divider, Layout } from 'antd';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { BurgerButton } from '@atoms/burgerButton/BurgerButton';
import { CancelOrderMessage } from '@constants/messages';
import { RoutesList } from '@constants/routesList';
import { HeaderActions } from '@customTypes/general/general.types';
import { useConfirmRedirect } from '@hooks/useConfirmRedirect';
import { SvgChoiceLogo } from '@icons/SvgChoiceLogo';
import { ConfirmModal } from '@molecules/confirmModal/ConfirmModal';
import { ConfirmRedirectModal } from '@organisms/confirmRedirectModal/ConfirmRedirectModal';
import { AccountSelect } from '@organisms/header/accountSelect/AccountSelect';
import { useHeader } from '@organisms/header/useHeader';
import { UserInfoWidget } from '@organisms/header/userInfoWidget/UserInfoWidget';
import { Notifications } from '@organisms/notifications/Notifications';
import { useRedirectChecker } from '@redirect/useRedirectChecker';

import styles from './Header.module.less';

const { Header: LayoutHeader } = Layout;

interface Props {
    actions: HeaderActions;
    toggleSiderOnMobile: (isOpen: boolean) => void;
    isSiderOpenOnMobile: boolean;
}

export const Header = ({
    actions: { signOut },
    toggleSiderOnMobile,
    isSiderOpenOnMobile
}: Props) => {
    const {
        accounts,
        userInfo,
        description,
        selectedRadio,
        accountNumber,
        isPopupVisible,
        isAccountVisible,
        isChangeSubAccountOpen,
        onAccountSelect,
        onToggleBurgerMenu,
        toggleChangeAccount,
        handleInitialValues,
        onChangeAccountSubmit,
        disableAccountSelect
    } = useHeader({
        toggleSiderOnMobile,
        isSiderOpenOnMobile
    });

    const navigate = useNavigate();
    const blockRedirectCondition = useRedirectChecker();

    const onMainLogoClick = (path: string) => {
        toggleSiderOnMobile?.(false);
        setRedirectPath?.(path);

        blockRedirectCondition ? modalToggle?.() : navigate(path);
    };

    const {
        isModalOpen,
        modalToggle,
        setRedirectPath,
        onOkRedirect,
        setModalData
    } = useConfirmRedirect(signOut);

    return (
        <div className={styles.Wrapper} id="HeaderWrapper">
            <LayoutHeader id="HeaderLayout" className={styles.HeaderLayout}>
                <div className={classNames(styles.Row, styles.Container)}>
                    <div className={styles.Button}>
                        <BurgerButton
                            isOpen={isSiderOpenOnMobile}
                            onClick={onToggleBurgerMenu}
                        />
                    </div>
                    <div
                        className={styles.Logo}
                        onClick={() => onMainLogoClick(RoutesList.HOME.ROOT)}
                    >
                        <SvgChoiceLogo />
                    </div>
                    <div
                        className={classNames(
                            styles.Container__Left,
                            styles.Row,
                            { [styles.Hidden]: isSiderOpenOnMobile }
                        )}
                    >
                        {isAccountVisible && (
                            <AccountSelect
                                disable={disableAccountSelect}
                                data={accounts}
                                value={selectedRadio}
                                account={accountNumber}
                                subAccount={description}
                                onSelect={onAccountSelect}
                                visible={isPopupVisible}
                                onVisibleChange={handleInitialValues}
                                triggerBtnClass={styles.Row}
                            />
                        )}
                    </div>
                    {isAccountVisible && (
                        <Divider
                            className={classNames(styles.Divider__Dark, {
                                [styles.Hidden]: isSiderOpenOnMobile
                            })}
                            type="vertical"
                        />
                    )}
                    <div className={styles.Row}>
                        <Notifications
                            redirectModalToggle={modalToggle}
                            setRedirectPath={setRedirectPath}
                            setModalData={setModalData}
                        />
                        <Divider
                            className={styles.Divider__Light}
                            type="vertical"
                        />
                        <UserInfoWidget
                            signOut={signOut}
                            userInfo={userInfo}
                            className={styles.Header__UserInfo}
                            dropdownClassName={styles.UserInfo__Dropdown}
                            onMenuItemClick={toggleSiderOnMobile}
                            modalToggle={modalToggle}
                            setRedirectPath={setRedirectPath}
                        />
                    </div>
                </div>
            </LayoutHeader>
            <ConfirmModal
                className={styles.ChangeAccountModal}
                visible={isChangeSubAccountOpen}
                onOk={onChangeAccountSubmit}
                onCancel={toggleChangeAccount}
                onCancelButtonClick={toggleChangeAccount}
                mainText="Change selected subaccount?"
                submitText="Change Subaccount"
                description={CancelOrderMessage}
            />

            <ConfirmRedirectModal
                visible={isModalOpen}
                onOk={onOkRedirect}
                onCancelButtonClick={modalToggle}
                onCancel={modalToggle}
            />
        </div>
    );
};
