import { lazy } from 'react';

const CycleCountPerformancePage = lazy(
    () =>
        import(
            'components/pages/analytics/cycleCountPerformance/CycleCountPerformance.page'
        )
);

const DockToStockPage = lazy(
    () => import('components/pages/analytics/dockToStock/DockToStock.page')
);

const GlobalCapabilitiesPage = lazy(
    () =>
        import(
            'components/pages/analytics/globalCapabilities/GlobalCapabilities.page'
        )
);

const OrderHistoryPage = lazy(
    () => import('components/pages/analytics/orderHistory/OrderHistory.page')
);

const OutboundDriveFillRatePage = lazy(
    () =>
        import(
            'components/pages/analytics/outboundDriveFillRate/OutboundDriveFillRate.page'
        )
);

const DrivePerformancePage = lazy(
    () =>
        import(
            'components/pages/analytics/outboundDrivePerformance/DrivePerformance.page'
        )
);

const TenderToCarrierPerformancePage = lazy(
    () =>
        import(
            'components/pages/analytics/tenderToCarrierPerformance/TenderToCarrierPerformance.page'
        )
);

const DutiesTaxesAnalysisReport = lazy(
    () =>
        import(
            '@pages/analytics/dutiesTaxesAnalysisReport/DutiesTaxesAnalysisReport'
        )
);

const InvoiceReport = lazy(
    () => import('components/pages/financials/invoiceReport/InvoiceReport')
);

const StorageBillingReport = lazy(
    () =>
        import(
            'components/pages/financials/storageBillingReport/StorageBillingReport'
        )
);

const InventoryOnHandPage = lazy(
    () =>
        import(
            'components/pages/manageInventory/inventoryOnHand/InventoryOnHand.page'
        )
);

const MaterialAnalysisPage = lazy(
    () =>
        import(
            'components/pages/manageInventory/materialAnalysis/MaterialAnalysis.page'
        )
);

const MissingProductsPage = lazy(
    () => import('@pages/manageInventory/missingProducts/MissingProducts.page')
);

const TransactionHistoryPage = lazy(
    () =>
        import(
            'components/pages/manageInventory/transactionHistory/TransactionHistory.page'
        )
);

const ProductMasterPage = lazy(
    () => import('@pages/manageProducts/productMaster/ProductMaster.page')
);

const SubstituteProductsPage = lazy(
    () =>
        import(
            '@pages/manageProducts/substituteProducts/SubstituteProducts.page'
        )
);

const InvoiceBillingPage = lazy(
    () =>
        import('components/pages/financials/invoiceBilling/InvoiceBillingPage')
);

const SpendAnalysisReport = lazy(
    () =>
        import(
            'components/pages/analytics/spendAnalysisReport/SpendAnalysisReport'
        )
);

export {
    CycleCountPerformancePage,
    DockToStockPage,
    GlobalCapabilitiesPage,
    OrderHistoryPage,
    OutboundDriveFillRatePage,
    DrivePerformancePage,
    TenderToCarrierPerformancePage,
    InvoiceBillingPage,
    SpendAnalysisReport,
    DutiesTaxesAnalysisReport,
    InvoiceReport,
    StorageBillingReport,
    InventoryOnHandPage,
    MaterialAnalysisPage,
    MissingProductsPage,
    TransactionHistoryPage,
    ProductMasterPage,
    SubstituteProductsPage
};
