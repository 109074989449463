import { Sorting, SORTING_DIRECTION } from '@customTypes/general/general.types';
import {
    OrdersListSearchParams,
    TRACKING_SEARCH_PARAMS
} from '@customTypes/mirAndTracking/MirAndTracking.types';
import { getBasicSort } from '@helpers/getBasePaginationBody';
import { DefaultActiveStatuses } from '@helpers/orderStatus';
import { BASIC_SEARCH_PARAMS } from '@helpers/paramsHandler';
import { getSortingObject } from '@helpers/tablePaginationAndSorting';
import { numberOrUndefined } from '@organisms/trackOrders/orderList/ordersListUrlUtils';

const defaultSortingState: Sorting = {
    field: 'LatestMilestoneDate',
    direction: SORTING_DIRECTION.ASC
};

export interface useCurrentParamsReturnType {
    currentParams: OrdersListSearchParams;
}

export const useCurrentParams = (
    searchParams: URLSearchParams,
    sortingInitialState: Sorting
): useCurrentParamsReturnType => {
    const sortingFieldData = searchParams.get(BASIC_SEARCH_PARAMS.SORTS);
    const sortingData = getSortingObject(sortingFieldData, sortingInitialState);
    const orderTypes = searchParams.get('orderTypes') || undefined;
    const productConditionTypes =
        searchParams.get(TRACKING_SEARCH_PARAMS.PRODUCT_CONDITION) || undefined;
    const deliveryTypes =
        searchParams.get(TRACKING_SEARCH_PARAMS.DELIVERY_TYPES) || undefined;
    const accountsAndSubs =
        searchParams.get(BASIC_SEARCH_PARAMS.ACCOUNTS_AND_SUBS) || undefined;
    const transportTypes =
        searchParams.get(BASIC_SEARCH_PARAMS.TRANSPORT_TYPES) || undefined;
    const originLocations =
        searchParams.get(TRACKING_SEARCH_PARAMS.ORIGIN_LOCATIONS) || undefined;
    const destinationLocations =
        searchParams.get(TRACKING_SEARCH_PARAMS.DESTINATION_LOCATIONS) ||
        undefined;

    const dateFrom = searchParams.get('dateFrom') || undefined;
    const dateTo = searchParams.get('dateTo') || undefined;
    const dateType = searchParams.get('dateType') || undefined;

    const search = searchParams.get(BASIC_SEARCH_PARAMS.SEARCH) || undefined;
    const status =
        searchParams.get(BASIC_SEARCH_PARAMS.STATUS) ||
        DefaultActiveStatuses.join('|');

    const page = numberOrUndefined(searchParams.get(BASIC_SEARCH_PARAMS.PAGE));
    const pageSize = numberOrUndefined(
        searchParams.get(BASIC_SEARCH_PARAMS.PAGE_SIZE)
    );

    const showDangerousGoods =
        searchParams.get('showDangerousGoods') || undefined;

    const showWatchList = searchParams.get('showWatchList') || undefined;
    const currentParams: OrdersListSearchParams = {
        sorts: getBasicSort(sortingData),
        sortingData: sortingData || defaultSortingState,
        page,
        pageSize,
        orderTypes,
        productConditionTypes,
        deliveryTypes,
        dateFrom,
        dateTo,
        dateType,
        search,
        accountsAndSubs,
        transportTypes,
        originLocations,
        destinationLocations,
        showDangerousGoods,
        status,
        showWatchList
    };

    return {
        currentParams: currentParams
    };
};
