import { createSlice } from '@reduxjs/toolkit';

import { customerDeliveryOrderByProductApi } from '@apis/customerDeliveryOrderByProduct/CustomerDeliveryOrderByProductApi';
import {
    inboundReplenishmentApi,
    inboundOrderByProductDefectiveApi
} from '@apis/inboundReplenishment/InboundReplenishment.api';
import { inboundReturnApi } from '@apis/inboundReturn/InboundReturn.api';
import {
    materialsTransferByWarehouseApi,
    materialsTransferByWarehouseDefectiveApi
} from '@apis/materialsTransferByWarehouse/MaterialsTransferByWarehouse.api';
import { outboundOrderByWarehouseDefectiveApi } from '@apis/outboundOrderByWarehouse/OutboundOrderByWarehouse.api';
import { warehouseApi } from '@apis/warehouse/Warehouse.api';
import { initialPaginationState } from '@constants/pagination';
import { MasterInitialState } from '@customTypes/api/api.types';
import { Pagination } from '@customTypes/general/general.types';
import { GetProductTransformedResponse } from '@customTypes/general/product.types';
import { handleFulfilledPagination } from '@helpers/handleFulfilledPagination';
import { Action } from 'store/index';

export interface InitialProductMaster extends MasterInitialState {
    data: GetProductTransformedResponse['data']['items'];
}

const initialState: InitialProductMaster = {
    loading: false,
    data: [],
    pagination: initialPaginationState,
    errors: { code: 0, message: '' }
};

export const productMasterSlice = createSlice({
    name: 'productMaster',
    initialState,
    reducers: {
        editPagination(
            state,
            action: Action<
                Partial<Omit<Pagination, 'pagesCount' | 'totalItemsCount'>>
            >
        ) {
            state.pagination = {
                ...state.pagination,
                ...action.payload
            };
        },
        resetPagination(state) {
            state.pagination = initialPaginationState;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            customerDeliveryOrderByProductApi.endpoints.getCustomerOrderProducts
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            inboundOrderByProductDefectiveApi.endpoints.getProductsMaterials
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            warehouseApi.endpoints.getWarehouseProducts.matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            outboundOrderByWarehouseDefectiveApi.endpoints.getWarehouseProducts
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            materialsTransferByWarehouseApi.endpoints.getWarehouseProducts
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            materialsTransferByWarehouseDefectiveApi.endpoints
                .getWarehouseProducts.matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            inboundReplenishmentApi.endpoints.getProductsMaterials
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            inboundReturnApi.endpoints.getProducts.matchFulfilled,
            handleFulfilledPagination
        );
    }
});

export const { editPagination, resetPagination } = productMasterSlice.actions;
