export enum PERMISSIONS_KEYS {
    // Customer Delivery
    customerDeliveryGood = 'customer-delivery-good-parts',
    customerDeliveryGoodProductsUpload = 'customer-delivery-good-parts-upload',

    // Replenishments
    replenishmentGoodProductsUpload = 'replenishments-good-parts-upload',
    replenishmentGoodProducts = 'replenishments-good-parts',

    // Returns
    returnGoodFromChoice = 'return-parts-good-from-choice',
    returnDefectiveFromChoice = 'return-parts-defective-from-choice',
    returnDefectiveFromChoiceUpload = 'return-parts-defective-from-choice-upload',
    returnGoodToChoice = 'return-parts-good-to-choice',
    returnDefectiveToChoice = 'return-parts-defective-to-choice',
    returnDefectiveToChoiceUpload = 'return-parts-defective-to-choice-upload',

    // Warehouse Transfer
    warehouseTransferGood = 'warehouse-transfers-good-parts',
    warehouseTransferGoodProductsUpload = 'warehouse-transfers-good-parts-upload',
    warehouseTransferDefectiveProducts = 'warehouse-transfers-defective-parts',
    warehouseTransferDefectiveProductsUpload = 'warehouse-transfers-defective-parts-upload',

    // Manage inventory
    locateClosestWarehouse = 'manage-inventory-locate-closest-warehouse',
    inventoryOnHand = 'manage-inventory-inventory-on-hand',
    inventoryTransactionHistory = 'manage-inventory-inventory-transaction-history',
    materialInReview = 'manage-inventory-material-in-review',
    materialInReviewAnalysis = 'manage-inventory-materials-in-review-analysis',
    missingProduct = 'manage-inventory-missing-parts',

    // Financials
    invoices = 'financials-invoices',
    storageBilling = 'financials-storage-billing',
    clientBilling = 'financials-client-billing',
    clientSpend = 'financials-client-spend',
    dutiesTaxesAnalysis = 'financials-duties-taxes-analysis',

    // Analytics
    analyticsOrderHistory = 'analytics-order-history',
    cycleCountPerformance = 'analytics-cycle-count-performance',
    globalCapabilities = 'analytics-global-capabilities',
    dockToStock = 'analytics-dock-to-stock',
    outboundDriveFillRate = 'analytics-outbound-drive-fill-rate',
    outboundDrivePerformance = 'analytics-outbound-drive-performance',
    tenderToCarrierPerformance = 'analytics-tender-to-carrier-performance',

    // Admin Panel
    admins = 'admins',
    users = 'users',
    profiles = 'profiles',
    choiceAdmins = 'choice-admins',

    // Manage parts
    productMaster = 'manage-parts-part-master',
    substituteProducts = 'manage-parts-substitute-parts',

    trackOrders = 'track-orders',
    orderHistory = 'order-history'
}

export enum MENU_SECTIONS_PERMISSION_KEYS {
    customerDelivery = 'customer-delivery',
    replenishments = 'replenishments',
    returnProducts = 'return-parts',
    createTransfer = 'warehouse-transfers',
    manageInventory = 'manage-inventory',
    financials = 'financials',
    analytics = 'analytics',
    adminPanel = 'admin-panel',
    trackOrders = 'track-orders',
    home = 'home'
}
